// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Location from "../../blocks/location/src/Location";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SearchHistory from "../../blocks/SearchHistory/src/SearchHistory";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import AudioCall from "../../blocks/AudioCall/src/AudioCall";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Payments from "../../blocks/Payments/src/Payments";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import VideoCall5 from "../../blocks/VideoCall5/src/VideoCall5";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Customform from "../../blocks/customform/src/Customform";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import Download from "../../blocks/Download/src/Download";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Share from "../../blocks/share/src/Share";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";



const routeMap = {
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
Location:{
 component:Location,
path:"/Location"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SearchHistory:{
 component:SearchHistory,
path:"/SearchHistory"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
AudioCall:{
 component:AudioCall,
path:"/AudioCall"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
OrderDetailView:{
 component:OrderDetailView,
path:"/OrderDetailView"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Payments:{
 component:Payments,
path:"/Payments"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
VideoCall5:{
 component:VideoCall5,
path:"/VideoCall5"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Customform:{
 component:Customform,
path:"/Customform"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
Download:{
 component:Download,
path:"/Download"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Share:{
 component:Share,
path:"/Share"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},

  Home: {
component:Scheduling,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
